import { createGlobalStyle } from 'styled-components';

// Color generator:
// - https://coolors.co/e0acd5-3993dd-414141-f4ebe8-29e7cd
const GlobalStyle = createGlobalStyle`

  html {  
    // Because Semantic UI uses 'rem' we can change this
    // to change the whole website font size.
    //
    // The default is 14px.
    font-size: 17px;
  }

  body {
      color: #323232;
  }

  /* reduce the code size of pre/code blocks */
  pre code {
    font-size: 0.85em;
  }

  .ui.text.container {
    // Overrides Semantic UI default
    // of the text container.
    //
    // Default is 700px.
    max-width: 770px !important;
  }

  a, .fake-link {
    color: #0091f2;
    // text-decoration-line: underline;
    // text-decoration-style: dotted;
  }

  a:hover, .fake-link:hover {
    color: #323232;
  }

  hr {
    border-style: none;
    border-top: 1px solid rgba(34, 36, 38, .15);
    line-height: 1;
    color: rgba(0,0,0,.85);
    height: 0;
    -webkit-tap-highlight-color: transparent;  
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }

  blockquote {  
    padding: 30px 60px 50px 60px;
    color: #656565;
    font-style: italic;
    margin: 0px;

    h3 {
      text-transform: uppercase;
      // text-decoration: underline;
    }

    hr {
      border-style: none;
      border-top: 1px solid rgba(34, 36, 38, .15);
      line-height: 1;
      color: rgba(0,0,0,.85);
      height: 0;
      -webkit-tap-highlight-color: transparent;  
      padding-bottom: 4px;  
      margin-top: 0px;
      margin-bottom: 10px;
      margin-left: 0px;
      margin-right: 0px;    
    }
  }

  pre {
    background-color: rgb(248, 249, 250);
    padding: 10px;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono';
    font-size: 1em;
    tab-size: 4;
    
    // wrap the text to new line
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }

  // *** 
  // TOOLTIP
  // ***
  .tooltip {
    position: relative;
    display: inline-block;
    color: #0091f2;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    margin-bottom: 5px;
    margin-left: -150px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    opacity: 0;
    transition: opacity 0.3s;
    line-height: 20px;
    font-size: 14px; 
    padding: 10px 10px 10px 10px;
    text-align: 'center';
  }
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    margin-left: -58px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`;

// body {
//     color: ${ props => (props.whiteColor ? 'white' : 'black') };
//     font - family: ${ props => props.theme.fontFamily };
// }

export default GlobalStyle;
