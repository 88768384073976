import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContentWrapper from './../shared/ContentWrapper';
import { selectIsUserLoggedIn } from '../shared/model/user/userSelectors';

export default (props) => {

  const { 
    component: Component, 
    ...rest
  } = props;

  const isUserLoggedIn = useSelector(state => selectIsUserLoggedIn(state));

  return <Route 
    {...rest} 
    render={(props) => (
      isUserLoggedIn ? (
        <Redirect to="/" />
      ) : (
        <ContentWrapper>
          <Component  {...props } />
        </ContentWrapper>
      )
    )} 
  />
};
