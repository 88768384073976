import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContentWrapper from '../shared/ContentWrapper';
import { selectIsUserLoggedIn } from '../shared/model/user/userSelectors';

/**
 * You can choose between passing in a `component` or using `render` function.
 */
export default ({ component: Component, render, ...rest }) => {
  const isUserLoggedIn = useSelector(state => selectIsUserLoggedIn(state));
  return <Route {...rest} render={(props) => {
    return isUserLoggedIn 
    ? (
      <ContentWrapper>
        {
          (Component
          ? <Component  {...props} />
          : render(props))
        }
      </ContentWrapper>
    ) 
    : (
      <Redirect to="/" />
    );
  }} />
};
