import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import './../../prism.css';

import SyntaxHighlighted from './SyntaxHighlighted';

export default ({ 
  string, 
  path, 
  onLoad = () => {},
}) => {

  const [markdownString, setMarkdownString] = useState(string ? string : '');

  if (path && markdownString === '') {
    fetch(path)
      .then(response => {
        return response.text()
      })
      .then(text => {
        setMarkdownString(text);
        onLoad();
      })
  }

  return (
    <SyntaxHighlighted>
      <ReactMarkdown
        source={markdownString}
        escapeHtml={false}
      />
    </SyntaxHighlighted>
  );
};
