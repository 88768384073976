import { Link } from 'react-router-dom';
import React from 'react';

import AlgorithmPage from '../../../../shared/components/algorithm/AlgorithmPage';
import {
  algorithmsPath
} from '../../../../routers/routingPaths';
import comingUpWithSolutionMarkdownPath from './coming-up-with-solution.md';
import descriptionMarkdownPath from './description.md';
import reflectionMarkdownPath from './reflection.md';
import understandProblemMarkdownPath from './understand-the-problem.md';
import writingTheSolutionMarkdownPath from './writing-the-solution.md';

export default () => (
  <AlgorithmPage
    title={'No Repeating Characters'}
    descriptionSectionMarkdownPath={descriptionMarkdownPath}
    descriptionSection={(
      <div>
        <hr />
        <p>Read the directions from <Link to={algorithmsPath}>'The Algorithms'</Link> section before solving.</p>
      </div>
    )}
    understandProblemSectionMarkdownPath={understandProblemMarkdownPath}
    understandProblemSection={(
      <div></div>
    )}
    comingUpSolutionSectionMarkdownPath={comingUpWithSolutionMarkdownPath}
    comingUpSolutionSection={(
      <div></div>
    )}
    writingSolutionSectionMarkdownPath={writingTheSolutionMarkdownPath}
    writingSolutionSection={(
      <div></div>
    )}
    reflectionSectionMarkdownPath={reflectionMarkdownPath}
    reflectionSection={(
      <div></div>
    )}
  />
);
