import React from 'react';

import { setWebsiteTitle, setWebsiteSubtitle } from './../helpers/title-setter';

const WebsiteTitle = ({ title, subtitle }) => {
  if (title) {
    setWebsiteTitle(title)
  } else {  
    setWebsiteSubtitle(subtitle);
  }
  return (<React.Fragment />)
};

export { WebsiteTitle as default };