import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background: none;
  color: #0091f2;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  :hover {
    color: #323232;
  }
`;

/**
 eslint gives an error if you use <a onClick={}></a> with no `href`
 so I created this to satisfy the warning

 https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
*/
export default ({ children, ...rest }) => (
  <Button {...rest}>
    {children}
  </Button>
);
