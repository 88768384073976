import React from 'react';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';

const Body = styled.div`
  background-color: #F9F9F9;
  padding-top: 100px;
  padding-bottom: 20px;
`;

const Header = styled.h1`
  font-size: 50px;
`;

const ArticleHeader = ({ title }) => (
  <Body>
    <Container text>
      <Header>{title}</Header>
    </Container>
  </Body>
);

export default ArticleHeader;