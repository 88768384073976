import { Icon, Input } from 'semantic-ui-react'
import React from 'react';
import styled from 'styled-components';

const Body = styled.div`
  .input {
    margin-bottom: 4px;
  }

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export default ({ email, password, setEmail, setPassword, style }) => {

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Body style={style}>
      <Input iconPosition='left' placeholder='Email'>
        <Icon name='at' />
        <input value={email} onChange={onEmailChange} />
      </Input>
      <Input iconPosition='left' placeholder='Password (>= 6 characters)'>
        <Icon name='lock' />
        <input type='password' value={password} onChange={onPasswordChange} />
      </Input>
    </Body>
  );
};
