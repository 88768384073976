import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';

// Routes
import {
  algorithmsPath,
  algorithmInterviewPath,
  algorithmPracticeProcessPath,
  algorithmQuestionsPath,
  basicsOfPracticePath,
  courseChecklistPath,
  courseFAQPath,
  howToSolveProblemsPath,
  introductionPath,
  jumpGameAlgorithmPath,
  learningSciencePath,
  lessContentMorePracticePath,
  missingNumbersAlgorithmPath,
  noRepeatingCharactersAlgorithmPath,
  obstacleGridAlgorithmPath,
  pointToNextAlgorithmPath,
  practiceAlgorithmsPath,
  resourcesPath,
  rotateListAlgorithmPath,
  studyTerminologyPath,
  systemDesignInterviewPath,
  techInterviewsPath,
  twoSumAlgorithmPath,
  understandAlgorithmInterviewPath,
} from './routingPaths';
import AllRoute from './AllRoute';
import PublicRoute from './PublicRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import DynamicRoute from './DynamicRoute';
import SubscribedRoute from './SubscribedRoute';
import RedirectRoute from './RedirectRoute';

// Pages / Components
import AlgorithmInterview from '../pages/algorithm-interview/AlgorithmInterview.js';
import AlgorithmPracticeProcess from '../pages/algorithm-interview/AlgorithmPracticeProcess.js';
import AlgorithmQuestions from '../pages/algorithm-questions/AlgorithmQuestions';
import AnalyticsComponent from '../analytics/AnalyticsComponent';
import BasicsOfPractice from './../pages/basics-of-practice/BasicsOfPractice';
import CourseChecklist from './../pages/course-checklist/CourseChecklist';
import Discourse from '../pages/discourse/Discourse';
import PointToNext from '../pages/algorithm-interview/algorithms/PointToNext/PointToNext';
import Course from './../pages/course/Course';
import CourseFAQ from './../pages/course-faq/CourseFAQ';
import Home from './../pages/home/Home';
import HowToSolveProblems from '../pages/algorithm-interview/HowToSolveProblems.js';
import Introduction from './../pages/introduction/Introduction';
import JumpGame from '../pages/algorithm-interview/algorithms/JumpGame/JumpGame';
import LearningScience from './../pages/learning-science/LearningScience';
import LessContentMorePractice from'./../pages/less-content-more-practice/LessContentMorePractice';
import MissingNumbers from './../pages/algorithm-interview/algorithms/MissingNumbers/MissingNumbers';
import NoRepeatingCharacters from './../pages/algorithm-interview/algorithms/NoRepeatingCharacters/NoRepeatingCharacters';
import ObstacleGrid from './../pages/algorithm-interview/algorithms/ObstacleGrid/ObstacleGrid';
import PageNotFound from '../shared/PageNotFound';
import Payment from '../pages/payment/Payment';
import PaymentSuccess from '../pages/payment/PaymentSuccess';
import PracticeAlgorithms from '../pages/algorithm-interview/PracticeAlgorithms.js';
import PublicPage from '../shared/PublicPage';
import Resources from './../pages/resources/Resources';
import RotateList from './../pages/algorithm-interview/algorithms/RotateList/RotateList';
import SitePassword from '../pages/SitePassword';
import SecretPage from '../shared/SecretPage';
import StudyTerminology from '../pages/algorithm-interview/StudyTerminology.js';
import SystemDesignInterview from '../pages/system-design-interview/SystemDesignInterview';
import TestPage from '../pages/TestPage.js';
import TechInterviews from '../pages/tech-interviews/TechInterviews.js';
import TwoSum from '../pages/algorithm-interview/algorithms/TwoSum/TwoSum';
import TheAlgorithms from '../pages/algorithm-interview/TheAlgorithms.js';
import UnderstandAlgorithmInterview from '../pages/algorithm-interview/UnderstandAlgorithmInterview.js';
import UserDataDeletion from '../pages/extra/UserDataDeletion/UserDataDeletion';

// Docs: https://reacttraining.com/react-router/web/api/
const AppRouter = () => {
  const isSiteAccessGranted = (window.localStorage.getItem('site-password') == '85595d28e5b4afe6e622db4da3b7d2b0');
  const paymentPageRender = (props) => (<Payment {...props} />);
  return (
    <BrowserRouter>
      <Switch>
        {
          false ? <TestPage /> : null
        }
        <DynamicRoute 
          exact path="/" 
          publicRender={(props) => (<Home {...props} />)} 
          authenticatedRender={paymentPageRender} 
          subscribedRender={(props) => (<Course {...props} />)}
        />
        {/* /paymentCancel is a duplicate of `/` path to show the cancelled page in analytics */}
        <AuthenticatedRoute path="/paymentCancel" render={paymentPageRender} />
        <PublicRoute exact path={courseFAQPath} component={CourseFAQ} />
        <PublicRoute exact path="/public" component={PublicPage} />
        <AuthenticatedRoute path="/paymentSuccess" component={PaymentSuccess} />
        <SubscribedRoute exact path="/secret" component={SecretPage} />

        {/* Main Sections */}
        <SubscribedRoute exact path={learningSciencePath} component={LearningScience} />
        <SubscribedRoute exact path={introductionPath} component={Introduction} />
        <SubscribedRoute exact path={techInterviewsPath} component={TechInterviews} />
        <SubscribedRoute exact path={algorithmInterviewPath} component={AlgorithmInterview} />
        <SubscribedRoute exact path={courseChecklistPath} component={CourseChecklist} />
        
        {/* Algorithm Interview Sections */}
        <SubscribedRoute exact path={understandAlgorithmInterviewPath} component={UnderstandAlgorithmInterview} />
        <SubscribedRoute exact path={studyTerminologyPath} component={StudyTerminology} />
        <SubscribedRoute exact path={practiceAlgorithmsPath} component={PracticeAlgorithms} />
        <SubscribedRoute exact path={howToSolveProblemsPath} component={HowToSolveProblems} />
        <SubscribedRoute exact path={algorithmPracticeProcessPath} component={AlgorithmPracticeProcess} />
        <SubscribedRoute exact path={algorithmsPath} component={TheAlgorithms} />

        {/* Algorithms */}
        <AllRoute exact path={twoSumAlgorithmPath} component={TwoSum} />
        <SubscribedRoute exact path={jumpGameAlgorithmPath} component={JumpGame} />
        <SubscribedRoute exact path={pointToNextAlgorithmPath} component={PointToNext} />
        <SubscribedRoute exact path={noRepeatingCharactersAlgorithmPath} component={NoRepeatingCharacters} />
        <SubscribedRoute exact path={rotateListAlgorithmPath} component={RotateList} />
        <SubscribedRoute exact path={obstacleGridAlgorithmPath} component={ObstacleGrid} />
        <SubscribedRoute exact path={missingNumbersAlgorithmPath} component={MissingNumbers} />

        {/* System Design Interview Section */}
        <SubscribedRoute exact path={systemDesignInterviewPath} component={SystemDesignInterview} />

        {/* Supplementary Sections */}
        <SubscribedRoute exact path={basicsOfPracticePath} component={BasicsOfPractice} />
        <SubscribedRoute exact path={resourcesPath} component={Resources} />
        <SubscribedRoute exact path={lessContentMorePracticePath} component={LessContentMorePractice} />
        <SubscribedRoute exact path={algorithmQuestionsPath} component={AlgorithmQuestions} />

        {/* Other */}
        <Route exact path={'/user-data-deletion'} component={UserDataDeletion} />
        <AuthenticatedRoute exact path="/discourse/sso" component={Discourse} />
        <Route exact path={courseFAQPath} component={CourseFAQ} />
        <Route exact path={'/site-password'} component={SitePassword} />

        <Route component={PageNotFound} />
      </Switch>

      <AnalyticsComponent />

      {
      /* 
        * If user has not entered password, redirect them to do so. 
        * Otherwise, render nothing.
        */
        
      <RedirectRoute 
        redirectPath='/site-password' 
        shouldRenderComponent={isSiteAccessGranted} 
        render={() => {
          return null;
        }}
      />
      }
    </BrowserRouter>
  );
};

export default AppRouter;
