import React from 'react';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';

const Body = styled.div`
  padding-top: 40px;
  padding-bottom: 100px;
`;

const ArticleContent = ({ children }) => (
  <Body>
    <Container text>
      {children}
    </Container>
  </Body>
);

export default ArticleContent;