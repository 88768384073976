import { Container, Menu, MenuItem } from 'semantic-ui-react'
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { startLogout } from '../shared/model/user/userActions';
import {
  selectIsUserLoggedIn,
} from '../shared/model/user/userSelectors';
import SignInModal from '../pages/home/signin/SignInModal';
import {
  logContactClick,
  logShowSignInModalClick,
} from './../analytics/analytics-logs';

// This is abstracted into its own component because we
// want to re-use it for the loading state.
export const NavigationBarUI = (props) => {
  const {
    isLoading = false,
    isUserLoggedIn = false,
    onLogoPress = () => { },
    onLogOut = () => { },
    onLogIn = () => { },
  } = props;
  return (
    <div>
      <Menu attached={'top'} size={'huge'} stackable >
        <Container>
          <MenuItem
            header
            children={<h3>interview #</h3>}
            onClick={onLogoPress}
          />
          <Menu.Menu position='right'>
            <MenuItem
              onClick={() => {
                window.open("mailto:interviewhash@gmail.com", '_blank')
                logContactClick('navigation_bar');
              }}
              link name={'Contact'}
            />
            {
              !isLoading && (
                isUserLoggedIn ?
                  <MenuItem
                    onClick={onLogOut}
                    link name={'Sign Out'}
                  /> :
                  <MenuItem
                    onClick={onLogIn}
                    link
                    name={'Existing Users'}
                  />
              )
            }
          </Menu.Menu>
        </Container>
      </Menu>
    </div>
  )
};

const NavigationBar = (props) => {

  const history = useHistory();
  const onLogoPress = () => {
    history.push('/');
  };

  const dispatch = useDispatch();

  const [showSignInModal, setShowSignInModal] = useState(false);
  const onSignInModalClose = () => {
    setShowSignInModal(false);
  };

  const onLogIn = (e) => {
    logShowSignInModalClick('navigation_bar');
    setShowSignInModal(true);
  };

  const onLogOut = (e) => {
    dispatch(startLogout());
  };

  const isUserLoggedIn = useSelector(state => selectIsUserLoggedIn(state));
  // Menu docs:
  // https://semantic-ui.com/collections/menu
  // size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge' | 'massive'
  //
  // React docs:
  // - https://react.semantic-ui.com/elements/input/
  return (
    <div>
      <NavigationBarUI
        isLoading={false}
        isUserLoggedIn={isUserLoggedIn}
        onLogIn={onLogIn}
        onLogOut={onLogOut}
        onLogoPress={onLogoPress}
      />
      {
        showSignInModal && (
          <SignInModal onClose={onSignInModalClose} />
        )
      }
    </div>
  );
};

export default NavigationBar;
