import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ContentWrapper from '../shared/ContentWrapper';
import { 
    selectIsUserLoggedIn,
    selectIsUserSubscribed
} from '../shared/model/user/userSelectors';

export default ({ publicRender, authenticatedRender, subscribedRender, ...rest }) => {

    const isAuthenticated = useSelector(state => selectIsUserLoggedIn(state));
    const isSubscribed = useSelector(state => selectIsUserSubscribed(state));

    let contentRender;
    if (isAuthenticated && isSubscribed) {
        contentRender = subscribedRender;
    } else if (isAuthenticated) {
        contentRender = authenticatedRender;
    } else {
        contentRender = publicRender;
    }

    return (
        <Route 
            {...rest} 
            render={(props) => {
                return (
                    <ContentWrapper {...props}>
                        {contentRender(props)}
                    </ContentWrapper>
                );
            }}
        />
    );
};
