import React, { useState } from 'react';
import styled from 'styled-components';

import A from './../A';
import Markdown from './../Markdown';

const Body = styled.div`

`;

const Title = styled.div`
  margin: 0px;
  display: flex;
  align-items: center;
  /* Stole this value from the semantic defaults */
  margin-top: calc(2rem - 0.142857em);
  margin-bottom: 1rem;

  h2 {
    padding-right: 10px;
    margin: 0px;
  }
  p {
    padding-top: 3px;
  }
  a:hover {
    cursor: pointer;
  }
`;

export default ({ title, children, markdownPath }) => {

  const [show, setShow] = useState(false);
  const [isMarkdownLoaded, setIsMarkdownLoaded] = useState(!markdownPath);

  const onShowHide = (e) => {
    e.preventDefault();
    setShow(!show);
    if (!show && markdownPath) {  
      setIsMarkdownLoaded(false); // the markdown has to be reloaded after hiding
    }
  };
  const onMarkdownLoad = () => {
    setIsMarkdownLoaded(true);
  };

  return (
    <Body>
      <Title>
        <h2>{title}</h2>
        <p>
          <A onClick={onShowHide}>
            - {show ? 'hide' : 'show'} -
          </A>
        </p>
      </Title>
      {
        show ? (
          <div>
            {markdownPath && <Markdown path={markdownPath} onLoad={onMarkdownLoad} />}
            {isMarkdownLoaded && children}
          </div>
        ) : null
      }
    </Body>
  );
};