import { currentUser } from './../auth/auth';
import { functions } from './../firebase/firebase';

/**
 * Update the current user's fields. 
 * 
 * @param {string} expirationDate 
 */
export const updateCurrentUser = async (expirationDate) => {

  const uid = currentUser().uid;
  if (!uid) {
    throw Error('You are currently not logged in.');
  }
};

export const handleDiscourseSSORequest = async (parameters) => {
  const handleDiscourseSSORequest = functions().httpsCallable('handleDiscourseSSORequest');
  return new Promise((resolve, reject) => {
    handleDiscourseSSORequest({
      parameters,
    })
    .then((result) => {
      resolve(result.data);
    })
    .catch((error) => {
      // error.code, error.details
      reject(error.message);
    });
  });
};
