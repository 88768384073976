import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import React from 'react';

import Article from '../../shared/components/article/Article';

const PaymentSuccess = () => {

  eval("fbq('track', 'Purchase', { value: 4.99, currency: 'USD' });");
  
  return (
    <Article title='Success 🎉'>
      <p>Thank you for trusting us with your studying!</p>
      
      <p>Now begins your journey to mastering technical interviews. Remember...it's not supposed to be easy. There <i>will</i> be crappy days. Just trust the process and make sure to keep yourself accountable by not skipping any steps.</p>
      <Link to='/'>
        <Button color='blue'>Proceed To Course</Button>
      </Link>
    </Article>
  );
};

export default PaymentSuccess;
