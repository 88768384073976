import { Button, Message, Segment } from 'semantic-ui-react';
import React, { useState } from 'react';
import styled from 'styled-components';

import ArticleSection from './../../shared/components/article/ArticleSection';
import SignUpButtons from './signup/SignUpButtons';
import HomeHeader from './HomeHeader';
import WebsiteTitle from './../../shared/components/WebsiteTitle';
import {
  SHOW_REFUND_MESSAGE
} from './../../shared/model/constants';

import {
  courseFAQPath,
} from './../../routers/routingPaths';

const Body = styled.div`
  li {  
    font-size: 24px;
  }
  p { 
    font-size: 24px;
  }
`;

export default (props) => {
  const [showFreeAlgorithmPopup, setShowFreeAlgorithmPopup] = useState(true);

  return (
    <div>
      <WebsiteTitle title={'Technical Interview Preparation & Questions'} />
      <Body>
        <HomeHeader />

        <ArticleSection white>
          <p>Interview Hash is an online course that teaches how to pass tech interviews...</p>
          <p style={{ textAlign: 'right' }}><b>...but it is not your typical course.</b></p>
          <br />
          <br />
          <p>Instead of just focusing on algorithms and data structures, <b>I will also teach you problem solving and the science of learning</b>:</p>
          <ul>
            <li>What are the best problem solving methods I can use to solve <i>any</i> algorithm question?</li>
            <li>How can I structure my interview practice so I can learn quickly and effectively?</li>
          </ul>
        </ArticleSection>

        <ArticleSection>
          <center>
            <h1>My Story</h1>
          </center>
          <br />
          <p>Hi, I'm <a href='https://www.linkedin.com/in/%F0%9F%91%8B-krisjanis-gaidis-1486a848/' target='_blank' rel='noopener noreferrer'>Kris.</a> </p>
          <p>On my journey to working at top tech companies, I struggled with algorithm interviews. <b>Even after reading a lot of books on algorithms and data structures, I was still not able to get a job offer.</b></p>
          <p>After numerous failures, I didn't know what else I could do.</p>
          <p>I took a step back, and asked myself: "What are the <a href='https://www.youtube.com/watch?v=NV3sBlRgzTI' target='_blank' rel='noopener noreferrer'>core principles</a> of what I am trying to do?" The answer was learning and problem solving. <b>I started to read books on how humans acquire new skills and supplemented that information with the basics of problem solving.</b> I began to view the process of studying for tech interviews to be no different than that of acquiring new skill like playing the guitar.</p>
          <p>…and the rest is history.</p>
          <p>My failures are the advantage to this course. I know what it takes to go from failing to passing and I am confident that my experience will help others too.</p>
        </ArticleSection>
        
        <ArticleSection white>
          <center>
            <h1>The Course</h1>
          </center>
          <br />
          <p>In the course you will learn…</p>
          <ul>
            <li>the format and expectations of a technical interview</li>
            <li>the most effective way to prepare for a technical interview (hint: the science of learning!)</li>
            <li>what algorithms and data structures you should know before the interview</li>
            <li>how to develop a problem solving system that you can apply to all algorithm problems</li>
            <li><a href={courseFAQPath} target='_blank' rel="noopener noreferrer">...and more</a></li>
          </ul>
          <br/>
          <br/>
          <p>In the course I also provide you with various algorithms that I've broken down with lessons from the course. <a href='/algorithms/two-sum' target='_blank' rel='noopener noreferrer'>Preview one algorithm for free!</a></p>
        </ArticleSection>

        <ArticleSection>
          <center>
            <h1>Pricing</h1>
          </center>
          <br/>
          <Segment padded={'very'}>
            <center>
              <p>
              You can get the full course for just 
              &nbsp;<strike>$149</strike> 
              <b>
                  &nbsp;$4.99
              <span className='tooltip'>
                *
                <span className='tooltiptext'>
                  <i>If you can't afford the course, please send a message describing your story and why you want to take it. I can't promise to respond to everyone, but a select few will get free access to the class. One of the reasons for creating this course was to help those who were not lucky enough to have access to great mentorship. The course builds knowledge from the ground-up and doesn't assume you've been taught how to study effectively.</i>
                </span>
              </span>
              </b>.
              Sign up now - the price will increase later as more students sign up!
              </p>
            </center>
            <ul>
              {
                SHOW_REFUND_MESSAGE && (<li><b>Risk-free</b>: if the course didn't <a href={courseFAQPath} target='_blank' rel="noopener noreferrer">teach you anything</a>, contact for a full refund.</li>)
              }
              <li>Six month access.</li>
              <li>No subscription to cancel - it's a one time charge.</li>
              <li>Get all new content updates for free.</li>
            </ul>
            <br/>
            <br />
            <center>
              <p style={{'fontSize': '15px'}}>To begin, choose a sign-up method:</p>
              <SignUpButtons />
            </center>
          </Segment>
        </ArticleSection>

        <ArticleSection white>
          <center>
            <h1>Why Interview Hash?</h1>
          </center>
          <br />
          <p>Interview Hash is different.</p>
          <p>The goal of the course is <i>not</i> to explain what a Binary Tree is. The goal of the course is to give you the blueprint of how to prepare for technical interviews. <b>Think of it as a coach that nudges you in the correct direction.</b></p>
          <p><b>Technical interview preparation courses, or books, can give a false sense of security</b>: “If I take the course ‘X’ or read the book ‘Y,’ I will learn everything I need.” It might be the case that they teach you everything, and you can pass the interview. However, if you are struggling with algorithm interviews, that’s probably not the case.</p>
          <p>It makes perfect sense why you are not able to efficiently solve algorithms after reading one book or taking one course. <b>The brain doesn’t change in 1 month</b>. It doesn’t change without purposeful practice, continued dedication, and ample time. <b>If you take guitar lessons for one month, will you be a skilled guitar player?</b></p>
          <p>Interview Hash doesn't assume you know how to effectively practice for technical interviews. It gives you a practice plan that you can use to <i>finally</i> ace the interview!</p>
        </ArticleSection>

        <ArticleSection>
          {/* add extra lines for padding */}
        </ArticleSection>
      </Body>

      {/* Floating message bar for the free algorithm */}
      {
        showFreeAlgorithmPopup && (
          <div style={{ position: 'fixed', bottom: 30, right: 30, width: 200, textAlign: 'center'}}>
            <Message 
              onDismiss={() => {
                setShowFreeAlgorithmPopup(false);
              }} 
              color={'orange'}
            >
              <Message.Header>Free Algorithm!</Message.Header>
              <p>Checkout a breakdown of an algorithm in the course.</p>
              <Button 
                fluid 
                size={'medium'}
                color={'orange'}
                onClick={() => {
                  window.open('/algorithms/two-sum')
                }}
              >
                Preview
              </Button>
            </Message>
          </div>)
      }
      
    </div>
  );
};
