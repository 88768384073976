import React from 'react';

import Article from '../../shared/components/article/Article';
import markdownPath from './how-to-solve-problems.md';

export default () => (
  <Article 
    title={'How To Solve Problems'}
    markdownPath={markdownPath}
  >
  </Article>
);
