import React from 'react';

import Article from '../../shared/components/article/Article';
import markdownPath from './understand-algorithm-interview.md';

export default () => (
  <Article 
    title={'Understand The Algorithm Interview'}
    markdownPath={markdownPath}
  />
);
