import { firestore } from '../firebase/firebase';

const database = firestore();
export default database;

// example stuff:
// - https://github.com/builderbook/builderbook/tree/master/server/api
// - https://builderbook.org/books/builder-book/introduction#project-structure
//
// - HackerNews
//    - https://github.com/clintonwoo/hackernews-react-graphql

export const createUserIfNeeded = (uid) => {
    const userDocumentRef = database.collection('users').doc(uid);

    return userDocumentRef.get()
        .then((snapshot) => {
            // if user exists, return its data
            if (snapshot.exists) {
                return snapshot.data();
            } else {
                const user = {
                    uid
                };
                // if user does not exist, create it with `uid` field
                // and then return it
                return userDocumentRef.set(user)
                    .then(() => {
                        return user;
                    });
            }
        });
};





// const getUser = (uid) => {
//     return database
//         .collection('users')
//         // .where("uid", "==" , uid)
//         .doc(uid)
//         .get()
// }