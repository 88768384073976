
// Documentation for Custom Environment Variables:
// - https://create-react-app.dev/docs/adding-custom-environment-variables/
export const isProduction = () => {
  return process.env.NODE_ENV === 'production';
};

export const SHOW_REFUND_MESSAGE = true;

export const BASE_URL = isProduction() ? 'https://interviewhash.com' : 'http://localhost:3000';

/********************************
 *** STRIPE *********************
 ********************************/

// Access keys from:
// - https://dashboard.stripe.com/test/apikeys
//
// Checkout documentation:
// - https://stripe.com/docs/payments/checkout/one-time#client-only
//
// This is a 'publishable' API Key and doesn't have to be a secret:
// "Publishable API keys are meant solely to identify your account with Stripe,
// they aren’t secret. In other words, they can safely be published in places 
// like your Stripe.js JavaScript code, or in an Android or iPhone app."
//
// Stripe Payment Testing Tips:
// - https://stripe.com/docs/testing
export const STRIPE_API_KEY = isProduction() ? 'pk_live_iIqoshNQcNXeKXxYwgvN1Mp300CSRdIaF9' : 'pk_test_B5lWS0G8rzbVyzNLmuP8IvIl00TSYVNU7Y';

// Product Info:
// - https://dashboard.stripe.com/products/prod_Gx3UOpBX6nk2wy
export const STRIPE_PRODUCT_SKU = isProduction() ? 'sku_Gx3Uh9ZlcMwTbI' : 'sku_FgpBkBGWV15bJw';

export const STRIPE_CHECKOUT_SUCCESS_URL = `${BASE_URL}/paymentSuccess?session_id={CHECKOUT_SESSION_ID}`;
export const STRIPE_CHECKOUT_CANCEL_URL = `${BASE_URL}/paymentCancel`;

/********************************
 *** FIREBASE *******************
 ********************************/

// Get info from:
// - https://console.firebase.google.com/u/3/project/algo-3e2d4/settings/general/web
//
// Article on "hiding" public key:
// - https://medium.com/@impaachu/how-to-secure-your-firebase-project-even-when-your-api-key-is-publicly-available-a462a2a58843
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCvJpQaYhCBKOdFsAmOqkXIjbwy9gpzI20",
  // Configuration documentation:
  // - https://stackoverflow.com/a/48134235/826435
  authDomain: "interviewhash.com", //"algo-3e2d4.firebaseapp.com",
  databaseURL: "https://algo-3e2d4.firebaseio.com",
  projectId: "algo-3e2d4",
  storageBucket: "algo-3e2d4.appspot.com",
  messagingSenderId: "502940396254",
  appId: "1:502940396254:web:26f7f813f56b8242",
  measurementId: "G-WESL4CJGW4",
};

/********************************
 *** OTHER **********************
 ********************************/

 export const DEFAULT_WEBSITE_TITLE = "Interview Hash";