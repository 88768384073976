import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  /* width: 247px;
  height: 40px; */
  width: 222.3px;
  height: 36px;
  padding: 0;
  border: none;
  cursor: pointer;
  background: url(/images/facebook_signin.png);
  background-size: 100%; 

  :active {
    background: url(/images/facebook_signin.png);
    background-size: 100%; 
    border: none;
  }

  :focus { 
    outline: none;
  }
`;

export default ({ onClick }) => {
  return (
    <Button onClick={onClick} />
  );
};