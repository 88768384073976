import React from 'react';

import Article from '../../shared/components/article/Article';
import markdownPath from './less-content-more-practice.md';

export default () => {
  return (
    <Article 
      title={'Less Content, More Practice'}
      markdownPath={markdownPath}
    />
  );
};
