// Third-Party 
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'styled-components';

// CSS
import 'semantic-ui-css/semantic.min.css';

// Custom
import AppRouter from './routers/AppRouter';
import { auth } from './firebase/firebase';
import { createUserIfNeeded } from './database/database';
import GlobalStyle from './shared/styling/GlobalStyle';
import LoadingPage from './shared/LoadingPage';
import { login, logout } from './shared/model/user/userActions';
import store from './redux/createStore';

/******************************
 *** APPLICATION SETUP
 ******************************/

const rootElement = document.getElementById('root');

const renderInitialApp = (
    <React.Fragment>
        <LoadingPage />
        <GlobalStyle />
    </React.Fragment>
);
ReactDOM.render(renderInitialApp, rootElement);

let appDidRender = false;
const renderApp = () => {
    if (!appDidRender) {
        appDidRender = true;
        const app = (
            <Provider store={store}>
                <ThemeProvider theme={{ fontFamily: 'Helvetica Neue' }}>
                    <React.Fragment>
                        <AppRouter />
                        <GlobalStyle />
                    </React.Fragment>
                </ThemeProvider>
            </Provider>
        );
        ReactDOM.render(app, rootElement);
    }
};

/******************************
 *** HANDLE SUCCESSFUL AUTH
 ******************************/

auth().onAuthStateChanged((user) => {
    if (user) {
        createUserIfNeeded(user.uid)
            .then(user => {
                store.dispatch(login(user));
                renderApp();
            })
            .catch(() => {
                // clear log-in state if there is one
                store.dispatch(logout());
                renderApp();
            });
    } else {
        store.dispatch(logout());
        renderApp();
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
