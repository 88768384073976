import moment from 'moment';

import { LOGIN, LOGOUT, SUBSCRIBE } from './userActions';

const userReducer = (state = {}, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                id: action.user.uid,
                expirationDate: action.user.expiration_date ? action.user.expiration_date : null
            };
        case LOGOUT:
            return {};
        case SUBSCRIBE:
            return {
                ...state,
                expirationDate: action.expirationDate ? action.expirationDate : null
            };
        default:
            return state;
    }
};

export const selectUserId = (state) => {
    return state.id;
};

export const selectIsUserLoggedIn = (state) => {
    return state.id !== undefined;
};

export const selectIsUserSubscribed = (state) => {
    const expirationDate = moment(state.expirationDate);
    return moment().isBefore(expirationDate);
};

export const selectIsUsersFirstSubscription = (state) => !state.expirationDate;

export default userReducer;

// Naming Things
//
// action types - use constants, [NOUN]_[VERB], eg.USER_FETCH or MATERIAL_REQUEST_UPDATE
// action creators - [verb][Noun]() eg.fetchCurrentUser(), acceptMaterialRequest()
// selectors - get[NOUN]() or select[NOUN]() eg.getSelectedCards(), selectActiveRows()