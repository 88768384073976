// Setup docs:
// https://firebase.google.com/docs/web/setup?authuser=0

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";
// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/functions";

import { FIREBASE_CONFIG } from './../shared/model/constants';

firebase.initializeApp(FIREBASE_CONFIG);

const auth = firebase.auth;
const firestore = firebase.firestore;
const analytics = firebase.analytics;
const functions = firebase.functions;

export { auth, firestore, analytics, functions };
