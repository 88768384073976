import React from 'react';

import Article from './../article/Article.js';
import HideableSection from './HideableSection.js'

export default ({ 
  title, 
  extraHeader,
  descriptionSectionMarkdownPath,
  descriptionSection,
  understandProblemSectionMarkdownPath,
  understandProblemSection,
  comingUpSolutionSectionMarkdownPath,
  comingUpSolutionSection,
  writingSolutionSectionMarkdownPath,
  writingSolutionSection,
  reflectionSectionMarkdownPath,
  reflectionSection,
}) => {
  return (
    <Article 
      title={title}
      markdownPath={descriptionSectionMarkdownPath}
      extraHeader={extraHeader}
    >
      {descriptionSection}
      <HideableSection 
        title={'Understand The Problem'}
        markdownPath={understandProblemSectionMarkdownPath}
      >
        {understandProblemSection}
      </HideableSection>
      <HideableSection 
        title={'Coming Up With The Solution'}
        markdownPath={comingUpSolutionSectionMarkdownPath}
      >
        {comingUpSolutionSection}
      </HideableSection>
      <HideableSection 
        title={'Writing The Solution'}
        markdownPath={writingSolutionSectionMarkdownPath}
      >
        {writingSolutionSection}
      </HideableSection>
      <HideableSection 
        title={'Testing / Reflection'}
        markdownPath={reflectionSectionMarkdownPath}
      >
        {reflectionSection}
      </HideableSection>
    </Article>
  );
};