import React from 'react';

import Article from '../../../shared/components/article/Article';
import markdownPath from './user-data-deletion.md';

export default () => {
  return (
    <Article
      title={'User Data Deletion'}
      markdownPath={markdownPath}
    />
  );
};
