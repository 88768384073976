import { Link } from 'react-router-dom';
import { Message } from 'semantic-ui-react';
import React from 'react';
import { useSelector } from 'react-redux';

import AlgorithmPage from '../../../../shared/components/algorithm/AlgorithmPage';
import {
  algorithmsPath
} from './../../../../routers/routingPaths';
import comingUpWithSolutionMarkdownPath from './coming-up-with-solution.md';
import descriptionMarkdownPath from './description.md';
import reflectionMarkdownPath from './reflection.md';
import { selectIsUserSubscribed } from '../../../../shared/model/user/userSelectors';
import understandProblemMarkdownPath from './understand-the-problem.md';
import writingTheSolutionMarkdownPath from './writing-the-solution.md';

export default () => {

  const isUserSubscribed = useSelector(state => selectIsUserSubscribed(state));

  return (
    <AlgorithmPage
      title={'Two Sum'}
      extraHeader={!isUserSubscribed && (
        <Message color={'orange'}>
          <Message.Header>Notice For Unsubscribed Users</Message.Header>
          <p>This is one of the many algorithms explained in the course. If you are not subscribed, some links may not work.</p>
          <p>The primary purpose of these algorithms is not to show you how to solve a specific algorithm (ex. Two Sum). It is to show you how to think about solving problems, so you can find the solution to <i>any</i> algorithm problem.</p>
        </Message>
      )}
      descriptionSectionMarkdownPath={descriptionMarkdownPath}
      descriptionSection={(
        <div>
          <hr/>
          <p>Read the directions from <Link to={algorithmsPath}>'The Algorithms'</Link> section before solving.</p>
        </div>
      )}
      understandProblemSectionMarkdownPath={understandProblemMarkdownPath}
      understandProblemSection={(
        <div></div>
      )}
      comingUpSolutionSectionMarkdownPath={comingUpWithSolutionMarkdownPath}
      comingUpSolutionSection={(
        <div></div>
      )}
      writingSolutionSectionMarkdownPath={writingTheSolutionMarkdownPath}
      writingSolutionSection={(
        <div></div>
      )}
      reflectionSectionMarkdownPath={reflectionMarkdownPath}
      reflectionSection={(
        <div></div>
      )}
    />
  )
};
