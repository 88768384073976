import React from 'react';

import Article from '../../shared/components/article/Article';
import markdownPath from './course-faq.md';

export default () => {
  return (
    <Article 
      title={'Course FAQ'}
      markdownPath={markdownPath}
    />
  );
};
