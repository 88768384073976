import React from 'react';

import Article from '../../shared/components/article/Article';
import markdownPath from './algorithm-questions.md';

export default () => {
  return (
    <Article 
      title={'Algorithm Questions'}
      markdownPath={markdownPath}
    />
  );
};
