import React from 'react';
import { Route } from 'react-router-dom';
import ContentWrapper from './../shared/ContentWrapper';

export default (props) => {
  const {
    component: Component,
    ...rest
  } = props;
  return <Route
    {...rest}
    render={(props) => (
      <ContentWrapper>
        <Component  {...props} />
      </ContentWrapper>
    )}
  />
};
