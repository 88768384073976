import React, { useState } from 'react';
import md5 from 'md5';

export default () => {

  const [password, setPassword] = useState('');

  const onEnter = () => {
    window.localStorage.setItem('site-password', password);
    window.location.href = '/';
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div>
      <input value={password} onChange={onPasswordChange} />
      <button onClick={onEnter}>
        Enter
      </button>
    </div>
  );
};

