import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk'

import rootReducer from './rootReducer';
import { isProduction } from './../shared/model/constants';

// DevTools docs:
// - https://github.com/zalmoxisus/redux-devtools-extension#installation
const composeEnhancers = isProduction() ? compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);
const store = createStore(
    rootReducer, 
    composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;