import React from 'react';
import { Route, Redirect } from 'react-router-dom';

/*
 * RedirectRoute acts as a "base component" that will redirect
 * if `renderComponent` is `false`.
 */
export default ({ shouldRenderComponent, redirectPath, render, ...rest }) => {
  return <Route 
    render={(props) => {
      return shouldRenderComponent
        ? render(props)
        : <Redirect to={redirectPath} />
    }} 
    {...rest}
    />
};
