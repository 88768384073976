/*
 * Documentation:
 * - https://firebase.google.com/docs/reference/js/firebase.analytics.Analytics
 */

import { analytics } from './../firebase/firebase';
import { isProduction } from './../shared/model/constants';

const googleAnalytics = analytics(); // initialize

const shouldLogEvents = isProduction();

const debugLog = (message) => {
  console.log('[analytics]', message);
};

export const logEvent = (event, parameters) => {
  if (shouldLogEvents) {
    googleAnalytics.logEvent(event, parameters);
  } else {
    debugLog(`event: ${event}, parameters: ${JSON.stringify(parameters)}`);
  }
};

export const setCurrentScreen = (currentScreen) => {
  if (shouldLogEvents) {  
    googleAnalytics.setCurrentScreen(currentScreen);
  } else {  
    debugLog(`currentScreen: ${currentScreen}`);
  }
};

export const setUserProperties = (userProperties) => {
  if (shouldLogEvents) {  
    googleAnalytics.setUserProperties(userProperties);
  } else {  
    debugLog(`userProperties: ${JSON.stringify(userProperties)}`);
  }
};
