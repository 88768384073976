import { Button, Divider, Loader } from 'semantic-ui-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Article from './../../shared/components/article/Article';
import { selectIsUsersFirstSubscription } from './../../shared/model/user/userSelectors';
import { 
  SHOW_REFUND_MESSAGE,
  STRIPE_API_KEY,
  STRIPE_CHECKOUT_CANCEL_URL,
  STRIPE_CHECKOUT_SUCCESS_URL,
  STRIPE_PRODUCT_SKU,
} from './../../shared/model/constants';
import {
  logProceedPaymentClick,
} from './../../analytics/analytics-logs';

const PaymentBox = styled.div`
  p {
   margin: 0;
  }
`;

const Payment = () => {

  const isUserFirstSubscription = useSelector(state => selectIsUsersFirstSubscription(state));
  const userId = useSelector(state => state.user.id);
  const [state, setState] = useState({
    value: null, // can be 'loading' or 'error'
    errorMessage: null
  });

  const onRedirectToStripe = () => {

    eval("fbq('track', 'InitiateCheckout');");

    logProceedPaymentClick("access_to_course_page")

    // setIsLoading(true);
    setState({
      value: 'loading'
    });

    // Stripe documentation:
    // - https://stripe.com/docs/payments/checkout/one-time#client-only
    const stripe = window.Stripe(STRIPE_API_KEY);

    // This will redirect to Stripe checkout page.
    // If payment is successful:
    // 1. A webhook fires from Stripe, to Firebase Functions, which updates user expiration date.
    // 2. User is redirected to `successUrl`
    // 3. Because they are redirected, they are 'auto-loggedin' to Google
    //    by making a network request. This fetches their profile, which 
    //    has the updated expiration date.
    stripe.redirectToCheckout({
      items: [
        { sku: STRIPE_PRODUCT_SKU, quantity: 1 }
      ],
      successUrl: STRIPE_CHECKOUT_SUCCESS_URL,
      cancelUrl: STRIPE_CHECKOUT_CANCEL_URL,

      // Stripe Docs:
      // """
      // A unique string to reference the Checkout session. This can be a customer ID, 
      // a cart ID, or similar.It is included in the checkout.session.completed webhook 
      // and can be used to fulfill the purchase.
      // """
      clientReferenceId: userId
    }).then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      setState({
        value: 'error',
        errorMessage: result.error.message
      });
    });
  };

  eval("fbq('track', 'CompleteRegistration');");
  
  return (
    <Article title='Access The Course'>
      {
      isUserFirstSubscription ? (
        <div>
          <p>Thank you for signing up to the site!</p>
          <p>To continue your journey to getting job offers at the top tech companies, please proceed to payment.</p>
          { 
              SHOW_REFUND_MESSAGE && <p><b>Risk-free: if the course didn't teach you anything, contact for a full refund.</b></p>
          }
        </div>
      ) : (
        <div> 
          <p><b>THANK YOU</b> (really...) for previously subscribing to the site.</p>
          <p>Passing tech interviews is not easy, and it will require you to keep re-visiting the core concepts in this course.</p>
          <p>To continue getting all the updates to the content please consider signing up again!</p>
        </div>
      )
      }
      <br />
      <PaymentBox>
        <p>
          <Button 
            color={'blue'} 
            onClick={(state.value === 'loading') ? null : onRedirectToStripe}
          >
            {(state.value === 'loading') ? (<Loader active inline inverted size={'tiny'} />) : (SHOW_REFUND_MESSAGE ? 'Proceed To Risk-Free Payment' : 'Proceed To Payment')}
          </Button>
          
        </p>
        {
          (state.value === 'error') && (
            <p style={{ color: 'red' }}><b>{state.errorMessage}</b></p>
          )
        }
        <p>
          <i>(We use <a href='https://stripe.com' rel='noopener noreferrer' target='_blank'>Stripe</a> to provide safe and secure payments.)</i>
        </p>
      </PaymentBox>
      <br />
      <Divider />
      <br />
      <p><i>Having issues? Want to leave feedback? Have questions? Please <a href='mailto: interviewhash@gmail.com' target='_blank' rel='noopener noreferrer'>send a message</a>.</i></p>
   </Article>
  );
};

export default Payment;
