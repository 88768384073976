import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

import algorithmList from '../../pages/algorithm-interview/algorithms/algorithmList';
import Article from '../../shared/components/article/Article';
import {
  algorithmsPath,
  algorithmInterviewPath,
  algorithmPracticeProcessPath,
  algorithmQuestionsPath,
  basicsOfPracticePath,
  howToSolveProblemsPath,
  introductionPath,
  learningSciencePath,
  lessContentMorePracticePath,
  practiceAlgorithmsPath,
  resourcesPath,
  studyTerminologyPath,
  systemDesignInterviewPath,
  techInterviewsPath,
  understandAlgorithmInterviewPath,
  courseChecklistPath,
} from '../../routers/routingPaths';

const Body = styled.div`
  li {
    font-weight: bold;
  }
`;

const Course = () => {
  return (
    <Body>
      <Article title={'Course'}>
        <p>Welcome to the course! <b>For optimal learning, read from top-to-bottom.</b></p>

        <Link to={introductionPath}>
          <h2>0. Introduction</h2>
        </Link>
        <p>Learn more about why the course was made and how you should approach it.</p>

        <Link to={learningSciencePath}>
          <h2>1. The Science of Learning</h2>
        </Link>
        <p>Learn the science that led me to pass tech interviews.</p>

        <Link to={techInterviewsPath}>
          <h2>2. Tech Interviews</h2>
        </Link>
        <p>Learn the basics of tech interviews.</p>

        <Link to={algorithmInterviewPath}>
          <h2>3. The Algorithm Interview</h2>
        </Link>
        <p>Learn how to prepare for the most important interview.</p>
        <ol type='a'>
          <Link to={understandAlgorithmInterviewPath}>
            <li>Understand The Algorithm Interview</li>
          </Link>

          <Link to={studyTerminologyPath}>
            <li>Study Terminology</li>
          </Link>

          <Link to={practiceAlgorithmsPath}>
            <li>Practice Algorithms</li>
          </Link>
          <ol type='i'>
            <Link to={howToSolveProblemsPath}>
              <li>How To Solve Problems</li>
            </Link>
            <Link to={algorithmPracticeProcessPath}>
              <li>The Algorithm Practice Process</li>
            </Link>
            <Link to={algorithmsPath}>
              <li>The Algorithms</li>
            </Link>
            <ul>
              {algorithmList.map((algorithm) => (
                  <Link to={algorithm.path} key={algorithm.title}>
                    <li>{algorithm.title}</li>
                  </Link>
                ))}
            </ul>
          </ol>
        </ol>

        {/* The System Design Interview */}
        <Link to={systemDesignInterviewPath}>
          <h2>4. The System Design Interview</h2>
        </Link>
        <p>Learn how to prepare for the system design interview.</p>

        {/* Course Checklist */}
        <Link to={courseChecklistPath}>
          <h2>5. Course Checklist</h2>
        </Link>
        <p>Use the checklist to guide you through the course.</p>

        <h2><u>Supplementary Sections</u></h2>

        <Link to={lessContentMorePracticePath}>
          <h2>Less Content, More Practice</h2>
        </Link>
        <p>Why you need to spend less time searching for new material, and spend more time practicing.</p>
        
        <Link to={basicsOfPracticePath}>
          <h2>The Basics of Practice</h2>
        </Link>
        <p>Learn the basics of how to structure your practice schedule.</p>

        <Link to={algorithmQuestionsPath}>
          <h2>Algorithm Questions</h2>
        </Link>
        <p>What algorithm questions should you practice? Here are some candidates.</p>

        <Link to={resourcesPath}>
          <h2>Resources</h2>
        </Link>
        <p>Explore additional learning resources.</p>

        <br />
        <hr />
        <br />
        <p><i>What content would you like to see? Have any feedback? Please <a href='mailto:interviewhash@gmail.com' target='_blank' rel="noopener noreferrer">e-mail</a> all suggestions.</i></p>
      </Article>
    </Body>
  );
};

export default Course;
