import 'redux-thunk';
import moment from 'moment';

import { signInWithGoogle, signOut } from '../../../auth/auth';
import { updateCurrentUser } from './../../../database/apiService'

export const userActions = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT'
};

export const LOGIN = 'LOGIN';
export const login = (user) => ({
    type: LOGIN,
    user
});

// Docs on async actions:
// - https://redux.js.org/advanced/async-actions
export const startGoogleLogin = () => {
    return (dispatch) => {
        return signInWithGoogle()
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                // var token = result.credential.accessToken;
                // The signed-in user info.
                // var user = result.user;
            })
            .catch((error) => {
                // Handle Errors here.
                // var errorCode = error.code;
                // var errorMessage = error.message;
                // The email of the user's account used.
                // var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                // var credential = error.credential;
            });
    };
};

export const LOGOUT = 'LOGOUT';
export const logout = () => ({
    type: LOGOUT
});

export const startLogout = () => {
    return () => {
        return signOut();
    };
};

export const SUBSCRIBE = 'SUBSCRIBE';
export const subscribe = (expirationDate) => ({
    type: SUBSCRIBE,
    expirationDate
});

export const startSubscribe = () => {
    return (dispatch) => {
        const expirationDate = moment().add(1, 'minute').toISOString();
        return updateCurrentUser(expirationDate)
            .then(() => {
                dispatch(subscribe(expirationDate));
            });
    };
};