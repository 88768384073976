import { Button, Loader } from 'semantic-ui-react';
import React from 'react';

export default ({isLoading, content, onClick, ...rest}) => {
  return (
    <Button
      {...rest}
      onClick={isLoading ? null : onClick}
    >
      {
        isLoading ? (
            <Loader active inline inverted size={'tiny'} />
          ) : ( 
            content
          )
      }
    </Button>
    )
};
