import React from 'react';
import { useSelector } from 'react-redux';

import ContentWrapper from './../shared/ContentWrapper';
import RedirectRoute from './RedirectRoute';
import { selectIsUserLoggedIn, selectIsUserSubscribed } from '../shared/model/user/userSelectors';

export default ({ component: Component, ...rest }) => {

  const isUserLoggedIn = useSelector(state => selectIsUserLoggedIn(state));
  const isUserSubscribed = useSelector(state => selectIsUserSubscribed(state));

  return <RedirectRoute
    {...rest} 
    shouldRenderComponent={(isUserLoggedIn && isUserSubscribed)} 
    render={(props) => (
      <ContentWrapper>
        <Component {...props} />
      </ContentWrapper>
    )}
    redirectPath='/'
   />
};
