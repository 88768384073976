import React, { useState } from 'react';

import ArticleContent from './ArticleContent';
import ArticleHeader from './ArticleHeader';
import Markdown from '../Markdown';
import WebsiteTitle from '../WebsiteTitle';

const Article = ({ title, markdownString, markdownPath, extraHeader, children }) => {
  
  const [isMarkdownLoaded, setIsMarkdownLoaded] = useState(!markdownPath);
  const onMarkdownLoad = () => {
    setIsMarkdownLoaded(true);
  };

  return (
    <div>
      <WebsiteTitle subtitle={title} />
      <ArticleHeader title={title} />
      <ArticleContent>
        {
          // used to display additional content (ex. unsubscribed message)
          extraHeader
        }
        {
          (markdownString || markdownPath) &&
            <Markdown 
              string={markdownString} 
              path={markdownPath} 
              onLoad={onMarkdownLoad}
              />
        }
        { isMarkdownLoaded && children }
      </ArticleContent>
    </div>
  );
};

export default Article;