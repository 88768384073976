import React from 'react';

import Article from '../../shared/components/article/Article';
import markdownPath from './study-terminology.md';

export default () => (
  <Article
    title={'Study Terminology'}
    markdownPath={markdownPath}
  >
    {/* This section is not markdown because we will later add <Link>'s via HTML */}
    <h3><u>Data Structures</u></h3>
    <ul>
      <li>Arrays / Vectors / Array Lists</li>  
      <li>Hash Tables</li>
      <li>Trees (binary tree is the most common)</li>
      <li>Linked Lists</li>
      <li>Stacks</li>
      <li>Queues</li>
      <li>Graphs (less common)</li>
      <li>Tries (less common)</li>
    </ul>

    <p><b>Info to know for each data structure:</b></p>
    <ul>
      <li>What are the strengths of each data structure?</li>
      <li>What are the weaknesses of each data structure?</li>
      <li>In what situations is each data structure used? For what algorithms?</li>
      <li>What is the Big-O time for each instruction (read, write, get, add, remove, search etc.).</li>
    </ul>

    <h3><u>Algorithms</u></h3>
    <ul>
      <li>Breadth First Search</li>
      <li>Depth First Search</li>
      <li>Graphs - Traverals (inorder, postorder, preorder)</li>
      <li>Binary Search</li>
      <li>Tree Insert / Find / e.t.c.</li>
      <li>Merge Sort (less common)</li>
      <li>Quick Sort (less common)</li>
    </ul>

    <p><b>Info to know for each algorithm:</b></p>
    <ul>
      <li>Where is this algorithm used? In what situations?</li>
      <li>What data structures are utilized (if any) for each algorithm?</li>
      <li>What is the Big-O time for each algorithm?</li>
    </ul>

    <h3><u>Concepts</u></h3>
    <ul>
      <li>Big-O Time</li>
      <ul>
        <li>For supplementary practice, analyze the Big-O time of each algorithm you do. Make this a habit. It is common for the interviewer to ask you for the Big-O time.</li>
      </ul>

      <li>Recursion (Divide & Conquer)</li>

      <li>Backtracking</li>

      <li>Greedy</li>

      <li>Dynamic Programming / Memoization</li>

      <li>Bit Manipulation & Binary</li>
      <ul>
        <li>For supplementary practice, implement algorithms like “set bit” “get bit” “clear bit” etc.</li>
      </ul>

      <li>Powers of 2</li>
    </ul>
  </Article>
);
