import { Divider, Message, Modal } from 'semantic-ui-react';
import React, { useState } from 'react';

import EmailSignUpFields from './../signup/EmailSignUpFields';
import { 
  signInWithEmail,
  signInWithFacebook,
  signInWithGoogle,
} from './../../../auth/auth';
import LoadingButton from './../../../shared/components/LoadingButton';
import FacebookSignInButton from './../FacebookSignInButton';
import GoogleSignInButton from './../GoogleSignInButton';
import {
  logFacebookSSOClick,
  logGoogleSSOClick,
  logEmailSignInClick,
} from './../../../analytics/analytics-logs';

export default ({ onClose }) => {
  
  const defaultState = {
    value: null,
    errorMessage: null,
  };
  const [state, setState] = useState(defaultState);

  const resetState = () => {
    // Google and Facebook login do not use "loading"
    // only Email does because it changes the email button
    setState(defaultState);
  };

  const handleError = (error) => {
    if (
      error.code === 'auth/popup-closed-by-user' ||
      error.code === 'auth/cancelled-popup-request'
    ) {
      return;
    };
    setState({
      value: 'error',
      errorMessage: error.message,
    });
  };

  // Google
  const onGoogleSignIn = () => {
    logGoogleSSOClick("signin_modal");
    resetState();
    signInWithGoogle()
      .then(() => {
        onClose();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  // Facebook
  const onFacebookSignIn = () => {
    logFacebookSSOClick("signin_modal");
    resetState();
    signInWithFacebook()
      .then(() => {
        onClose();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  // Email
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const onEmailSignIn = () => {
    logEmailSignInClick("signin_modal");
    setState({
      value: 'loading',
    });
    signInWithEmail(email, password)
      .then(() => {
        onClose(); // auto-close if success
      })
      .catch((error) => {
        handleError(error);
      });
  };
  return (
    <Modal size={'mini'} open={true} onClose={onClose} closeIcon>
      <Modal.Header>Sign In</Modal.Header>
      <Modal.Content style={{ textAlign: 'center' }}>
        <GoogleSignInButton onClick={onGoogleSignIn} />
        <FacebookSignInButton onClick={onFacebookSignIn} />
        <Divider />
        <EmailSignUpFields
          style={{ marginTop: '20px', display: 'block' }}
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
        />
        <LoadingButton
          isLoading={state.value === 'loading'}
          primary
          content='Sign In'
          onClick={onEmailSignIn}
        />
        {
          (state.value === 'error') && (
            <div>
              <Divider />
              <Message
                style={{ textAlign: 'left' }}
                negative
                header='Error'
                content={state.errorMessage}
              />
            </div>
          )
        }
      </Modal.Content>
    </Modal>
  );
};
