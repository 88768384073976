import React from 'react';

import Article from '../../shared/components/article/Article';
import markdownPath from './algorithm-practice-process.md';

export default () => (
  <Article 
    title={'The Algorithm Practice Process'}
    markdownPath={markdownPath}
  >
  </Article>
);
