import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  selectIsUserSubscribed,
} from './../../shared/model/user/userSelectors';
import { handleDiscourseSSORequest } from './../../database/apiService';
import ArticleHeader from './../../shared/components/article/ArticleHeader';
import ArticleContent from './../../shared/components/article/ArticleContent';

const SubscribedUser = () => {
  const parameters = window.location.search;

  const [errorMessage, setErrorMessage] = useState(null);

  handleDiscourseSSORequest(parameters)
    .then((redirectUrl) => {
      window.location.replace(redirectUrl);
    })
    .catch((errorMessage) => {
      setErrorMessage(errorMessage);
    });

  return (
    <div>
      <ArticleHeader title={errorMessage ? 'Error' : 'Authenticating...'} />
      <ArticleContent>
        <p>{errorMessage ? errorMessage : ''}</p>
      </ArticleContent>
    </div>
  );
};

export default () => {
  const isSubscribed = useSelector(state => selectIsUserSubscribed(state));
  return isSubscribed
    ? <SubscribedUser />
    : (
      <div>
        <ArticleHeader title={'Error'} />
        <ArticleContent>
          <p>You must be subscribed to use Interview Hash Discourse.</p>
        </ArticleContent>
      </div>
    );
};
