import React from 'react';

import Article from '../../shared/components/article/Article';
import markdownPath from './introduction.md';

export default () => (
  <Article 
    title={'Introduction'} 
    markdownPath={markdownPath}
  />
);
