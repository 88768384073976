import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

import algorithmList from '../../pages/algorithm-interview/algorithms/algorithmList';
import Article from '../../shared/components/article/Article';
import markdownPath from './the-algorithms.md';

const Body = styled.div`

`;

export default () => (
  <Body>
    <Article 
      title={'The Algorithms'}
      markdownPath={markdownPath}
    >
      <hr/>
      <ul>
        {algorithmList.map((algorithm) => (
          <Link to={algorithm.path} key={algorithm.path}>
            <h2><li>{algorithm.title}</li></h2>
          </Link>
        ))}
      </ul>
    </Article>
  </Body>
);
