import { auth } from '../firebase/firebase'




// provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

// firebase.auth().languageCode = 'pt';
// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();

// provider.setCustomParameters({
//     'login_hint': 'user@example.com'
// });

// Google Sign-In docs:
// - https://firebase.google.com/docs/auth/web/google-signin?authuser=0
export const signInWithGoogle = () => {
    const provider = new auth.GoogleAuthProvider();
    return auth().signInWithPopup(provider);
        // .then((result) => {

        //     // This gives you a Google Access Token. You can use it to access the Google API.
        //     // var token = result.credential.accessToken;
        //     // The signed-in user info.
        //     // var user = result.user;
        // })
        // .catch((error) => {
        //     // Handle Errors here.
        //     // var errorCode = error.code;
        //     // var errorMessage = error.message;
        //     // The email of the user's account used.
        //     // var email = error.email;
        //     // The firebase.auth.AuthCredential type that was used.
        //     // var credential = error.credential;
        // });
};

export const signInWithFacebook = () => {
    const provider = new auth.FacebookAuthProvider()
    return auth().signInWithPopup(provider);
};

export const signUpWithEmail = (email, password) => {
    return auth().createUserWithEmailAndPassword(email, password);
};

export const signInWithEmail = (email, password) => {
    return auth().signInWithEmailAndPassword(email, password);
};

export const signOut = () => {
    return auth().signOut();
};

export const currentUser = () => {
    return auth().currentUser;
};
