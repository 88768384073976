import React from 'react';

import Article from '../../shared/components/article/Article';
import markdownPath from './system-design-interview.md';

export default () => {
  return (
    <Article 
      title={'The System Design Interview'}
      markdownPath={markdownPath}
    />
  );
};
