import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

import {
  algorithmsPath,
  algorithmPracticeProcessPath,
  howToSolveProblemsPath,
} from '../../routers/routingPaths';
import Article from '../../shared/components/article/Article';
import markdownPath from './practice-algorithms.md';

const Body = styled.div`
  li {
    font-weight: bold;
  }
`;

export default () => (
  <Body>
    <Article 
      title={'Practice Algorithms'}
      markdownPath={markdownPath}
    >
      
      
      <ol>
        <Link to={howToSolveProblemsPath}>
          <h3><li>How To Solve Problems</li></h3>
        </Link>

        <Link to={algorithmPracticeProcessPath}>
          <h3><li>The Algorithm Practice Process</li></h3>
        </Link>

        <Link to={algorithmsPath}>
          <h3><li>The Algorithms</li></h3>
        </Link>
      </ol>
    </Article>
  </Body>
);
