import { Container } from 'semantic-ui-react';
import React from 'react';
import styled from 'styled-components';

const Body = styled.div`
  background-color: ${props => props.white ? 'white' : '#FDFDFD'};
  padding-top: 50px;
  padding-bottom: 50px;
`;

const ArticleSection = (props) => {
  const {
    white = false
  } = props;

  return (
    <Body white={white}>
      <Container text>
        {props.children}
      </Container>
    </Body>
  );
};

export default ArticleSection;