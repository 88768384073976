import { reducerPaths } from '../../../redux/rootReducer';
import * as selectors from './userReducer';

const userPath = reducerPaths.user;

export const selectUserId = (state) => {
    return selectors.selectUserId(state[userPath]);
};

export const selectIsUserLoggedIn = (state) => {
    return selectors.selectIsUserLoggedIn(state[userPath]);
};

export const selectIsUserSubscribed = (state) => {
    return selectors.selectIsUserSubscribed(state[userPath]);
};

export const selectIsUsersFirstSubscription = (state) => {
    return selectors.selectIsUsersFirstSubscription(state[userPath]);
};
