import { DEFAULT_WEBSITE_TITLE } from './../model/constants';

const setTitle = (title) => {
  document.title = title;
};

export const setWebsiteTitle = (title) => {
  setTitle(title + ' | ' + DEFAULT_WEBSITE_TITLE);
};

export const setWebsiteSubtitle = (subtitle) => {
  setTitle(subtitle + ' | ' + DEFAULT_WEBSITE_TITLE);
};
