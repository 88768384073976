import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

import Article from '../../shared/components/article/Article';
import markdownPath from './algorithm-interview.md';
import {
  practiceAlgorithmsPath,
  studyTerminologyPath,
  understandAlgorithmInterviewPath,
} from '../../routers/routingPaths';

const Body = styled.div`
  li {
    font-weight: bold;
  }
`;

export default () => (
  <Body>
    <Article 
      title={'The Algorithm Interview'} 
      markdownPath={markdownPath}
    >
      <ol>
        <Link to={understandAlgorithmInterviewPath}>
          <h3><li>Understand The Algorithm Interview</li></h3>
        </Link>

        <Link to={studyTerminologyPath}>
          <h3><li>Study Terminology</li></h3>
        </Link>

        <Link to={practiceAlgorithmsPath}>
          <h3><li>Practice Algorithms</li></h3>
        </Link>
      </ol>
    </Article>
  </Body>  
);
