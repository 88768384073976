import React from 'react';

import Article from '../../shared/components/article/Article';
import markdownPath from './learning-science.md';

export default () => ( 
  <Article 
    title={'The Science of Learning'}
    markdownPath={markdownPath}
  />
);
