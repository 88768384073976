const EVENT = {
  SCREEN_VIEW: 'screen_view',
  CONTACT_CLICK: 'contact_click',
  DISCOURSE_CLICK: 'discourse_click',
  SHOW_SIGN_IN_MODAL_CLICK: 'show_signin_modal_click',
  SHOW_EMAIL_SIGN_UP_MODAL_CLICK: 'show_email_signup_modal_click',
  FACEBOOK_SSO_CLICK: 'facebook_sso_click',
  GOOGLE_SSO_CLICK: 'google_sso_click',
  EMAIL_SIGNIN_CLICK: 'email_signin_click',
  EMAIL_SIGNUP_CLICK: 'email_signup_click',
  PROCEED_PAYMENT_CLICK: 'proceed_payment_click',
};

export { EVENT as default };
