import {
  jumpGameAlgorithmPath,
  obstacleGridAlgorithmPath,
  pointToNextAlgorithmPath,
  twoSumAlgorithmPath,
  missingNumbersAlgorithmPath,
  noRepeatingCharactersAlgorithmPath,
  rotateListAlgorithmPath,
} from '../../../routers/routingPaths';

export default [
  {
    title: 'Two Sum',
    path: twoSumAlgorithmPath,
  },
  {
    title: 'Jump Game',
    path: jumpGameAlgorithmPath,
  },
  {
    title: 'Point To Next',
    path: pointToNextAlgorithmPath,
  },
  {
    title: 'No Repeating Characters',
    path: noRepeatingCharactersAlgorithmPath,
  },
  {
    title: 'Rotate List',
    path: rotateListAlgorithmPath,
  },
  {
    title: 'Obstacle Grid',
    path: obstacleGridAlgorithmPath,
  },
  {
    title: 'Missing Numbers',
    path: missingNumbersAlgorithmPath,
  }
];
