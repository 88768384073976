import { Message, Modal } from 'semantic-ui-react';
import React, { useState } from 'react';

import EmailSignUpFields from './EmailSignUpFields';
import { signUpWithEmail } from './../../../auth/auth'; 
import LoadingButton from './../../../shared/components/LoadingButton';
import { logEmailSignUpClick } from './../../../analytics/analytics-logs';

export default ({ onClose }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [state, setState] = useState({
    value: null,
    errorMessage: null,
  });

  const onSignUp = () => {

    logEmailSignUpClick("email_signup_modal");

    setState({
      value: 'loading',
    });

    signUpWithEmail(email, password)
      .catch((error) => {
        setState({
          value: 'error',
          errorMessage: error.message,
        });
      });
  };

  return (
    <Modal size={'mini'} open={true} onClose={onClose} closeIcon>
      <Modal.Header>Email Sign Up</Modal.Header>
      <Modal.Content>
        <EmailSignUpFields
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
        />
      </Modal.Content>
      
      <Modal.Actions>
        <LoadingButton 
          isLoading={state.value === 'loading'} 
          primary 
          content='Sign Up' 
          onClick={onSignUp} 
        />
        {
          (state.value === 'error') && (  
            <Message
              style={{textAlign: 'left'}}
              negative
              header='Error'
              content={state.errorMessage}
            />
          )
        }
      </Modal.Actions>
    </Modal>
  );
};
