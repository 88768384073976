import { Message } from 'semantic-ui-react';
import React, { useState } from 'react';

import EmailSignUpButton from './EmailSignUpButton';
import EmailSignUpModal from './EmailSignUpModal';
import FacebookSignInButton from './../FacebookSignInButton';
import GoogleSignInButton from './../GoogleSignInButton';
import { 
  signInWithGoogle,
  signInWithFacebook,
} from './../../../auth/auth';
import { 
  logFacebookSSOClick,
  logGoogleSSOClick,
  logShowSignUpModalClick,
} from './../../../analytics/analytics-logs';

 export default () => {

  const [errorMessage, setErrorMessage] = useState(null);
  const onGoogleSignIn = () => {
    logGoogleSSOClick("home_page");
    setErrorMessage(null);
    signInWithGoogle()
      .catch((error) => {
        if (
          error.code === 'auth/popup-closed-by-user' ||
          error.code === 'auth/cancelled-popup-request'
        ) {
          return;
        };
        setErrorMessage(error.message);
      });
  };

  const onFacebookSignIn = () => {
    logFacebookSSOClick("home_page");
    setErrorMessage(null);
    signInWithFacebook()
      .catch((error) => {
        if (
          error.code === 'auth/popup-closed-by-user' ||
          error.code === 'auth/cancelled-popup-request'
        ) {
          return;
        };
        setErrorMessage(error.message);
      });
  };

  const [isEmailSignUpModalOpen, setIsEmailSignUpModalOpen] = useState(false);
  const onShowEmailSignUpModal = () => {
    logShowSignUpModalClick("home_page");
    setErrorMessage(null);
    setIsEmailSignUpModalOpen(true);
  };
  const onEmailSignUpModalClose = () => {
    setIsEmailSignUpModalOpen(false);
  };

  return (
    <div>
      <div>
        <GoogleSignInButton onClick={onGoogleSignIn} />
      </div>
      <div>
        <FacebookSignInButton onClick={onFacebookSignIn} />
      </div>
      <EmailSignUpButton onClick={onShowEmailSignUpModal} />
      {
        isEmailSignUpModalOpen && ( 
          <EmailSignUpModal onClose={onEmailSignUpModalClose} />
        )
      }
      {
        errorMessage && (
          <Message
            style={{
              textAlign: 'left',
            }}
            negative
            header='Error'
            content={(
              <p style={{ fontSize: '1.14285714em'}}>{errorMessage}</p>
            )}
          />
        )
      }
    </div>
  );
 };