/*
 * Having issues with paths not matching?
 * - Make sure to add '/' at the beginning of each path, 
 *   otherwise, it will not work.
 */

// Public Pages
export const courseFAQPath = '/course-faq';

// Core Course
export const introductionPath = '/introduction';
export const learningSciencePath = '/the-science-of-learning';
export const techInterviewsPath = '/tech-interviews';
export const courseChecklistPath = '/course-checklist';

// Algorithm Interview
const algorithmInterviewRoot = 'algorithm-interview';
export const algorithmInterviewPath = `/${algorithmInterviewRoot}/algorithm-interview`;
export const understandAlgorithmInterviewPath = `/${algorithmInterviewRoot}/understand-algorithm-interview`;
export const studyTerminologyPath = `/${algorithmInterviewRoot}/study-terminology`;
export const practiceAlgorithmsPath = `/${algorithmInterviewRoot}/practice-algorithms`;
export const howToSolveProblemsPath = `/${algorithmInterviewRoot}/how-to-solve-problems`;
export const algorithmPracticeProcessPath = `/${algorithmInterviewRoot}/algorithm-practice-process`;
export const algorithmsPath = `/${algorithmInterviewRoot}/algorithms`;

// Algorithms
const algorithmsRoot = 'algorithms';
export const twoSumAlgorithmPath = `/${algorithmsRoot}/two-sum`;
export const jumpGameAlgorithmPath = `/${algorithmsRoot}/jump-game`;
export const pointToNextAlgorithmPath = `/${algorithmsRoot}/point-to-next`;
export const noRepeatingCharactersAlgorithmPath = `/${algorithmsRoot}/no-repeating-characters`;
export const rotateListAlgorithmPath = `/${algorithmsRoot}/rotate-list`;
export const obstacleGridAlgorithmPath = `/${algorithmsRoot}/obstacle-grid`;
export const missingNumbersAlgorithmPath = `/${algorithmsRoot}/missing-numbers`;

// System Design Interview
const systemDesignInterviewRoot = 'system-design-interview';
export const systemDesignInterviewPath = `/${systemDesignInterviewRoot}/system-design-interview`;

// Supplementary Sections
export const basicsOfPracticePath = '/basics-of-practice';
export const resourcesPath = '/resources';
export const lessContentMorePracticePath = '/less-content-more-practice';
export const algorithmQuestionsPath = '/algorithm-questions';
