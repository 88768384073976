import { combineReducers } from 'redux';
import userReducer from '../shared/model/user/userReducer';
import coreReducer from './core/coreReducer';

export const reducerPaths = {
  core: 'core',
  user: 'user',
};

export default combineReducers({
  [reducerPaths.user]: userReducer,
  [reducerPaths.core]: coreReducer,
});
