import React from 'react';
import NavigationBar from './NavigationBar';
import styled from 'styled-components';

const Body = styled.div`
    
`;

export default (props) => (
    <Body>
        <NavigationBar />
        {props.children}
    </Body>
);
