import { ENTER_SITE_PASSWORD } from './coreActions';

export default (state = {}, action) => {
  switch (action.type) {
    case ENTER_SITE_PASSWORD:
      return {
        'site_password': action.password
      }
    default:
      return state;
  };
};

export const selectIsSiteAccessGranted = (state) => state['site_password'] === 'Noah';