import {
  logEvent,
  setCurrentScreen,
} from './analytics';
import EVENT from './analytics-events';

export const logCurrentScreen = () => {
  // `location.search` if you want extra data (ex. after '?')
  setCurrentScreen(window.location.pathname);
  logEvent(EVENT.SCREEN_VIEW);
};

export const logContactClick = (source) => {
  logEvent(EVENT.CONTACT_CLICK, {
    source,
  });
};

export const logShowSignInModalClick = (source) => {
  logEvent(EVENT.SHOW_SIGN_IN_MODAL_CLICK, {
    source,
  });
};

export const logShowSignUpModalClick = (source) => {
  logEvent(EVENT.SHOW_EMAIL_SIGN_UP_MODAL_CLICK, {
    source,
  });
};

export const logFacebookSSOClick = (source) => {
  logEvent(EVENT.FACEBOOK_SSO_CLICK, {
    source,
  });
};

export const logGoogleSSOClick = (source) => {
  logEvent(EVENT.GOOGLE_SSO_CLICK, {
    source,
  });
};

export const logEmailSignInClick = (source) => {
  logEvent(EVENT.EMAIL_SIGNIN_CLICK, {
    source,
  });
};

export const logEmailSignUpClick = (source) => {
  logEvent(EVENT.EMAIL_SIGNUP_CLICK, {
    source,
  });
};

export const logProceedPaymentClick = (source) => {
  logEvent(EVENT.PROCEED_PAYMENT_CLICK, {
    source,
  });
};
