import { Container } from 'semantic-ui-react';
import React from 'react';
import styled from 'styled-components';

const Body = styled.div`
  background-color: #F9F9F9;
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
`;

const Header = styled.h1`
  font-size: 40px;
  text-align: center;
`;

const SubHeader = styled.p`
  font-size: 30px;
  text-align: center;
`;

const HomeHeader = () => (
  <Body>
    <Container text>
      <Header>I will teach you to be good at programming interviews.</Header>
      <SubHeader>There is a science-based way to learn new skills and <i>anyone</i> can utilize them to crack the coding interview.</SubHeader>
    </Container>
  </Body>
);

export default HomeHeader;