import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  width: 191px;
  height: 46px;
  padding: 0;
  border: none;
  background: url(/images/btn_google_signin_light_normal_web@2x.png);
  background-size: 100%; 
  cursor: pointer;

  :active {
    background: url(/images/btn_google_signin_light_pressed_web@2x.png);
    background-size: 100%; 
    border: none;
  }

  :focus { 
    outline: none;
  }
`;

const GoogleSignInButton = ({ onClick }) => {
  return (
    <Button onClick={onClick} />
  );
};

export default GoogleSignInButton;