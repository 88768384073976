import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { setUserProperties } from './analytics';
import { logCurrentScreen } from './analytics-logs';
import {
  selectIsUserLoggedIn,
  selectIsUserSubscribed,
  selectIsUsersFirstSubscription,
} from './../shared/model/user/userSelectors';

const AnalyticsComponent = () => {
  const history = useHistory();
  const isAuthenticated = useSelector(state => selectIsUserLoggedIn(state));
  const isSubscribed = useSelector(state => selectIsUserSubscribed(state));
  const isUserFirstSubscription = useSelector(state => selectIsUsersFirstSubscription(state));

  useEffect(() => {
    setUserProperties({
      'user_status': 
        (isAuthenticated && isSubscribed) 
        ? 'subscribed'
        : (
          isAuthenticated
          ? (
            isUserFirstSubscription
            ? 'authenticated_new_subscriber'
            : 'authenticated_previous_subscriber'
            )
          : 'unauthenticated'
        )
    });
    logCurrentScreen(); // log the first page visit
    const historyCleanup = history.listen(() => {
      logCurrentScreen();
    });
    return () => {
      historyCleanup();
    };
  }, [history, isUserFirstSubscription, isAuthenticated, isSubscribed]);
  return (<div></div>);
};

export { AnalyticsComponent as default };